<div class="loadingActions">
    <pea-loader *ngIf="isLoading"></pea-loader>
</div>
<div class="form-align circuit-form-field">
<div>
    <mat-card>
        <form [formGroup]="scenarioForm">
          <div class="align-item">
            <mat-form-field >
              <mat-label>Previous Scenario Run ID</mat-label>
              <mat-select formControlName="run_id">
                <mat-option *ngFor="let run_id of allRunIDs" [value]="run_id">{{run_id}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
            <div class="align-item">
                <mat-form-field >
                    <mat-label>Scenario Name</mat-label>
                    <input matInput type="text" formControlName="scenario_name">
                </mat-form-field>             
                <mat-form-field >
                  <mat-label>Region</mat-label>
                    <mat-chip-grid #regionChipList aria-label="id selection">
                      <mat-chip-row
                        class="mat-action"
                        *ngFor="let data of regions"
                        (removed)="removeRegion(data)">
                        {{data}}
                        <button matChipRemove>
                          <mat-icon>cancel</mat-icon>
                        </button>
                      </mat-chip-row>
                      <input
                        #iregion
                        formControlName="regions"
                        [matAutocomplete]="region"
                        [matChipInputFor]="regionChipList">
                    </mat-chip-grid>
                    <mat-autocomplete #region="matAutocomplete" (optionSelected)="selectedRegion($event)">
                      <mat-option *ngFor="let data of allRegions" [value]="data">
                        {{data}}
                      </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
                <mat-form-field >
                  <mat-label>Calculation</mat-label>
                  <mat-select formControlName="calculation_option">
                      <mat-option *ngFor="let cal of calculation_option" [value]="cal">{{cal | uppercase}}</mat-option>
                  </mat-select>
                </mat-form-field>  
            </div>
            <div class="align-item">
                <mat-form-field >
                    <mat-label>Forecast Driver</mat-label>
                    <mat-chip-grid #forecastDriverChipList aria-label="id selection">
                        <mat-chip-row
                          class="mat-action"
                          *ngFor="let data of forecastDrivers"
                          (removed)="removeForecastDriver(data)">
                          {{data}}
                          <button matChipRemove>
                            <mat-icon>cancel</mat-icon>
                          </button>
                        </mat-chip-row>
                        <input
                          #iforecastDriver
                          formControlName="forecast_driver"
                          [matAutocomplete]="forecastDriver"
                          [matChipInputFor]="forecastDriverChipList">
                      </mat-chip-grid>
                      <mat-autocomplete #forecastDriver="matAutocomplete" (optionSelected)="selectedForecastDriver($event)">
                        <mat-option *ngFor="let data of allForecastDrivers" [value]="data">
                          {{data}}
                        </mat-option>
                      </mat-autocomplete>       
                </mat-form-field>                
                <mat-form-field >
                  <mat-label>State</mat-label>
                    <mat-chip-grid #stateChipList aria-label="id selection">
                      <mat-chip-row
                        class="mat-action"
                        *ngFor="let data of states"
                        (removed)="removeState(data)">
                        {{data}}
                        <button matChipRemove>
                          <mat-icon>cancel</mat-icon>
                        </button>
                      </mat-chip-row>
                      <input
                        #istate
                        formControlName="states"
                        [matAutocomplete]="state"
                        [matChipInputFor]="stateChipList">
                    </mat-chip-grid>
                    <mat-autocomplete #state="matAutocomplete" (optionSelected)="selectedState($event)">
                      <mat-option *ngFor="let data of allStates" [value]="data">
                        {{data}}
                      </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
                <mat-form-field >
                  <mat-label>Processor Type</mat-label>
                  <mat-select formControlName="processor_type" (selectionChange)="checkProcessor($event)">
                      <mat-option *ngFor="let pt of processorType" [value]="pt">{{pt | uppercase}}</mat-option>
                  </mat-select>
                </mat-form-field> 
            </div>            
            <div class="align-item">
              <div class="second-column">
                <mat-form-field>                  
                    <mat-label>Substation ID</mat-label>
                    <mat-chip-grid #substationChipList aria-label="id selection">
                        <mat-chip-row
                          class="mat-action"
                          *ngFor="let id of substationIDs.slice(0, 3)"
                          (removed)="removeSubstationID(id)">
                          {{id}}
                          <button matChipRemove>
                            <mat-icon>cancel</mat-icon>
                          </button>
                        </mat-chip-row>
                        <mat-chip-row *ngIf="substationIDs.length > 3" id="substation-action-chip" class="mat-action" (click)="viewSelectedSubstationID()">View All</mat-chip-row>
                        <input
                          #substationID
                          formControlName="substation_ids"
                          [matAutocomplete]="substation"
                          [matChipInputFor]="substationChipList"
                          [(ngModel)]="substationInputValue"
                          (ngModelChange)="onSubstationInputChange()">
                      </mat-chip-grid>
                      <mat-autocomplete #substation="matAutocomplete" (optionSelected)="selectedSubstationID($event)">
                        <mat-option *ngFor="let id of filteredSubstationIDOptions" [value]="id">
                          {{id}}
                        </mat-option>
                      </mat-autocomplete>
                </mat-form-field>                
              </div>
              <ng-container *ngIf="isProcessorChecked">
                <mat-form-field >
                    <mat-label>Image Tag</mat-label>
                    <input matInput formControlName="image_tag">
                </mat-form-field>
              </ng-container>
            </div>
            <div class="align-item">
              <div class="second-column">
                <mat-form-field class="circuit-form-field">
                  <mat-label>Circuit ID</mat-label>
                  <mat-chip-grid #circuitChipList aria-label="id selection">
                    <mat-chip-row
                      class="mat-action"
                      *ngFor="let id of circuitIDs.slice(0, 3)"
                      (removed)="removeCircuitID(id)">
                      {{id}}
                      <button matChipRemove>
                        <mat-icon>cancel</mat-icon>
                      </button>
                    </mat-chip-row>
                    <mat-chip 
                      *ngIf="circuitIDs.length > 3" id="circuit-action-chip" class="mat-action" (click)="viewSelectedCircuitID()">View All
                    </mat-chip>
                    <input
                      #circuitID
                      formControlName="circuit_ids"
                      [matAutocomplete]="circuit"
                      [matChipInputFor]="circuitChipList"
                      [(ngModel)]="circuitInputValue"
                      (ngModelChange)="onCircuitInputChange()">
                  </mat-chip-grid>
                  <mat-autocomplete #circuit="matAutocomplete" (optionSelected)="selectedCircuitID($event)">
                    <mat-option *ngFor="let id of filteredCircuitIDOptions" [value]="id">
                      {{id}}
                    </mat-option>
                  </mat-autocomplete>
                </mat-form-field>
              </div>                          
              <mat-form-field >
                <mat-label>Skip Post-Process</mat-label>
                <mat-select formControlName="skip_postprocess">
                  <mat-option *ngFor="let spp of skipPostProcess" [value]="spp">{{spp | toString | uppercase}}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
        </form>
    </mat-card>
</div>
<div style="text-align: center; display: flex;justify-content: space-evenly;margin-top: 3%;">
    <button mat-raised-button mat-dialog-close color="primary" [disabled]="!scenarioForm.valid || !canSubmit" (click)="submit()">Submit</button>
    <button mat-raised-button color="warn" (click)="reset()">Reset</button>
</div>
</div>
