import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'pea-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit {
  isLoading = true;

  constructor() { }

  ngOnInit(): void {
    console.log();
    
  }

}
