import { Component, ViewChild} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ChartConfiguration, ChartEvent, ChartType } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';
import { PageHeaderService } from 'src/app/services/pageheader';
import { ScenarioService } from 'src/app/services/scenario.service';

@Component({
  selector: 'pea-fuse-coordination',
  templateUrl: './fuse-coordination.component.html',
  styleUrls: ['./fuse-coordination.component.scss'],
})
export class FuseCoordinationComponent {
  pageHeader: string = 'FUSE COORDINATION'
  fuseCoordinationForm: FormGroup;

  constructor( private fb: FormBuilder, private snackBar: MatSnackBar, private scenarioScervice: ScenarioService, private pageHeaderService: PageHeaderService, public dialog: MatDialog) {

    this.pageHeaderService.emitData(this.pageHeader);
    
    this.fuseCoordinationForm = this.fb.group({
      upstream_fuse_type: new FormControl('', [Validators.required]),
      new_fuse_asset_id: new FormControl('', [Validators.required]),
      max_fault_current: new FormControl(''),
      circuit_id: new FormControl(''),
      peak_load_flow_curent: new FormControl(''),
      downstream_fuse_type: new FormControl(''),
      output_date: new FormControl('', )
    })
  }
    

  public lineChartData: ChartConfiguration['data'] = {
    datasets: [
      {
        data: [{y:299.8919,x:130.1663},{y:143.908,x:138.116},{y:56.0881,x:157.6181},{y:20.7311,x:183.4637},{y:7.0876,x:228.4824},{y:2.6061,x:334.3216},{y:0.9573,x:528.3233},{y:0.3215,x:916.8229},{y:0.1297,x:1448.8412},{y:0.0484,x:2389.3216},{y:0.0206,x:3795.4939},{y:0.01,x:5611.7286}],
        label: 'MM Curve',
        backgroundColor: 'rgba(148,159,177,0.2)',
        borderColor: 'rgba(148,159,177,1)',
        pointBackgroundColor: 'rgba(148,159,177,1)',
        pointBorderColor: '#fff',
        pointHoverBackgroundColor: '#fff',
        pointHoverBorderColor: 'rgba(148,159,177,0.8)',
      },
      {
        data: [{y:299.8919,x:152.6175},{y:126.7596,x:161.266},{y:32.422,x:194.0619},{y:9.1065,x:260.2026},{y:3.1987,x:371.3498},{y:1.3478,x:543.9344},{y:0.4356,x:945.879},{y:0.177,x:1504.1146},{y:0.0738,x:2419.3275},{y:0.0383,x:3891.4226},{y:0.0222,x:6593.3433},{y:0.0164,x:9994.8013},{y:0.01,x:9994.8013}],
        label: 'TC Curve',
        backgroundColor: 'rgba(255,0,0,0.3)',
        borderColor: 'red',
        pointBackgroundColor: 'rgba(148,159,177,1)',
        pointBorderColor: '#fff',
        pointHoverBackgroundColor: '#fff',
        pointHoverBorderColor: 'rgba(148,159,177,0.8)',
      },
    ],
  };

  public lineChartOptions: ChartConfiguration['options'] = {
    elements: {
      line: {
        tension: 0.5,
      },
    },
    responsive: true,
    maintainAspectRatio: true,
    scales: {
      // We use this empty structure as a placeholder for dynamic theming.
      x: {
        type: 'logarithmic',
        min: .01,
        max: 10000,
        position: 'bottom',
        ticks: {
          maxTicksLimit: 6
        }
      },
      y: {
        type: 'logarithmic',
        min: .01,
        max: 1000,
        position: 'left',
        ticks: {
          maxTicksLimit: 10
        }
      },
    },
  };

  public lineChartType: ChartType = 'line';

  @ViewChild(BaseChartDirective) chart?: BaseChartDirective;

  private static generateNumber(i: number): number {
    return Math.floor(Math.random() * (i < 2 ? 100 : 1000) + 1);
  }

  public randomize(): void {
    for (let i = 0; i < this.lineChartData.datasets.length; i++) {
      for (let j = 0; j < this.lineChartData.datasets[i].data.length; j++) {
        this.lineChartData.datasets[i].data[j] =
          FuseCoordinationComponent.generateNumber(i);
      }
    }
    this.chart?.update();
  }

  // events
  public chartClicked({
    event,
    active,
  }: {
    event?: ChartEvent;
    active?: object[];
  }): void {
    console.log(event, active);
  }

  public chartHovered({
    event,
    active,
  }: {
    event?: ChartEvent;
    active?: object[];
  }): void {
    console.log(event, active);
  }
}
