import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'pea-grid',
  templateUrl: './pea-grid.component.html',
  styleUrls: ['./pea-grid.component.scss'],
})
export class PeaGridComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {
    console.log('');
  }
}
