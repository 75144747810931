import { Component } from '@angular/core';
import { PageHeaderService } from 'src/app/services/pageheader';

@Component({
  selector: 'pea-coming-soon',
  templateUrl: './coming-soon.component.html',
  styleUrls: ['./coming-soon.component.scss']
})
export class ComingSoonComponent {
  pageHeader: string = 'COMING SOON'

  constructor(private pageHeaderService: PageHeaderService){
    this.pageHeaderService.emitData(this.pageHeader);
  }

}
