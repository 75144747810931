<div class="loadingActions">
    <pea-loader *ngIf="isLoading"></pea-loader>
</div>

<ng-container *ngIf="isLoading === false">
    <div class="header">
        <h3>Batch Job Review Table</h3>
    </div>
</ng-container>

<section class="pea-dashboard-data__dashboard__container">
    <div class="pea-dashboard-data__dashboard__container--data__dashboard__container">

        <div id="dashboardContainer"></div>

    </div>
</section>
