import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { PageHeaderService } from 'src/app/services/pageheader';

@Component({
  selector: 'pea-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss'],
})
export class SidenavComponent {

  activeLink: string = '/'
  pageHeader: string = ''
  comingSoon = []


  constructor(private route: Router, private pageHeaderService: PageHeaderService){
    pageHeaderService.updatePageHeader.subscribe((data: string) => {
      this.pageHeader = data; 
    });
  }

  changeFontColor(link: string, name: string){
    this.pageHeader = name;
    this.activeLink = link;
    if(link == '/'){
      this.route.navigate(["/"]);
      this.pageHeaderService.emitData(this.pageHeader);
    }
    else if(this.comingSoon.includes(link)){
      this.route.navigate(["/coming-soon"]);
      this.pageHeaderService.emitData(this.pageHeader);
    }
    else{
      this.route.navigate(["/"+link]);
      this.pageHeaderService.emitData(this.pageHeader);

    }
  }

}
