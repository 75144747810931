import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, throwError } from 'rxjs';
import { PeaUiConfigComponent } from '../components/pea-ui-config/pea-ui-config.component';

@Injectable({
  providedIn: 'root'
})
export class ScenarioService {

  private baseUrl: string;
 
  constructor(
     private httpClient: HttpClient,
     private config: PeaUiConfigComponent,
 ) { 
     this.baseUrl = this.config.getConfig('aws_service_api');  
    
   }

  getScenario(): Observable<any> {
    const idUrl: string = this.baseUrl + '/pea-scenario';
    return this.httpClient.get(idUrl)
    .pipe(catchError(this.processError));
  }

  getScenarioInfo(): Observable<any> {
    const idUrl: string = this.baseUrl + '/circuits';
    //creating HttpParams for QueryStringParameters
    let params = new HttpParams();
    // if (data == 'true') {
    //   params = params.append('frozen_model', 'true');
    // }
    // else if (data == 'false') {
    //   params = params.append('frozen_model', 'false');
    // }
    return this.httpClient.get(idUrl, {params: params})
    .pipe(catchError(this.processError));
  }

  triggerScenario(data: any): Observable<any> {
    const idUrl: string = this.baseUrl + '/dashboard/scenarios';
    return this.httpClient.post(idUrl, data)
    .pipe(catchError(this.processError));
  }

  processError(err: any) {
    let message = '';
    if(err.error instanceof ErrorEvent) {
     message = err.error.message;
    } else {
     message = `Error Code: ${err.status}\nMessage: ${err.message}`;
    }
    console.log(message);
    return throwError(message);
  }
}
