<de-header class="custom-header" [siteTitle]="title+' - '+targetEnv" [headerLinks]="loginDisplay?loggedInheaderLinks:loggedOutheaderLinks" (navigation)="handleLinkClick($event)" (dukeLogoClick)="handleNavigation($event)" (secondaryLogoClick)="handleNavigation($event)"><h2 class="header-title">{{pageHeader}}</h2></de-header>
<div class="container">
  <div class="nav-setting">
    <pea-sidenav *ngIf="sidenavToggleCheck"></pea-sidenav>
    <div (click)="toggleAction()" class="toggle-sidenav" [ngStyle]="{'margin-left': sidenavToggleCheck ? '15.2rem' : '0.5rem'}">
        <mat-icon class="collapse-icon" *ngIf="sidenavToggleCheck" matTooltip="Close Nav" matTooltipPosition="right">arrow_back_ios</mat-icon>
        <mat-icon class="menu-icon" *ngIf="!sidenavToggleCheck" matTooltip="Expand Nav" matTooltipPosition="below">menu</mat-icon>
    </div>
    <!--This is to avoid reload during acquireTokenSilent() because of hidden iframe -->
    <div class="content" [ngStyle]="{'margin-left': sidenavToggleCheck ? '16.5rem' : '2rem'}">
      <router-outlet *ngIf="!isIframe"></router-outlet>
    </div>
  </div>
</div>

<router-outlet
  *ngIf="path === '/unauthorized' || path === '/error'"
></router-outlet>
