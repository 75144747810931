import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { ProfileComponent } from './components/profile/profile.component';
import { FailedComponent } from './components/failed/failed.component';

import { PeaGridComponent } from './components/pea-grid/pea-grid.component';
import { UnauthorizeComponent } from './components/unauthorize/unauthorize.component';
import { KpiComponent } from './components/kpi/kpi.component';
import { ScenarioComponent } from './components/scenario-page/scenario/scenario.component';
import { OpensearchComponent } from './components/opensearch/opensearch.component';
import { ScenarioSummaryComponent } from './components/scenario-page/scenario-summary/scenario-summary.component';
import { PqviResultComponent } from './components/scenario-page/pqvi-result/pqvi-result.component';
import { FormComponent } from './resuable_components/form/form.component';
import { ComingSoonComponent } from './components/coming-soon/coming-soon.component';
import { SccResultComponent } from './components/scenario-page/scc-result/scc-result.component';
import { SccValidationComponent } from './components/validation-page/scc-validation/scc-validation.component';
import { PqviValidationComponent } from './components/validation-page/pqvi-validation/pqvi-validation.component';
import { FuseCoordinationComponent } from './components/fuse-coordination/fuse-coordination.component';
import { SccDerImpactComponent } from './components/scc-der-impact-tab/scc-der-impact/scc-der-impact.component';
import { PeaQuicksightGuard } from './Guard/pea-quicksight.guard';
import { DisconnectedDerComponent } from './components/scc-der-impact-tab/disconnected-der/disconnected-der.component';

const routes: Routes = [
  {
    path: 'profile',
    component: ProfileComponent,
    canActivate: [MsalGuard],
  },
  {
    path: 'pea-grid',
    component: PeaGridComponent,
    canActivate: [MsalGuard],
  },
  {
    path: 'kpi-dashboard',
    component: KpiComponent,
    canActivate: [MsalGuard, PeaQuicksightGuard], data:{expctedRole: ['admin']} ,
  },
  {
    path: 'scenario-dashboard',
    component: ScenarioComponent,
    canActivate: [MsalGuard, PeaQuicksightGuard], data:{expctedRole: ['admin', 'super_user']} ,
  },
  {
    path: 'pqvi-result-dashboard',
    component: PqviResultComponent,
    canActivate: [MsalGuard, PeaQuicksightGuard], data:{expctedRole: ['admin', 'super_user', 'igrid_viz_load_flow']} ,
  },
  {
    path: 'scc-result-dashboard',
    component: SccResultComponent,
    canActivate: [MsalGuard, PeaQuicksightGuard], data:{expctedRole: ['admin', 'super_user', 'igrid_viz_scc']} ,
  },
  {
    path: 'scc-validation-dashboard',
    component: SccValidationComponent,
    canActivate: [MsalGuard, PeaQuicksightGuard], data:{expctedRole: ['admin', 'super_user']} ,
  },
  {
    path: 'pqvi-validation-dashboard',
    component: PqviValidationComponent,
    canActivate: [MsalGuard, PeaQuicksightGuard], data:{expctedRole: ['admin', 'super_user']} ,
  },
  {
    path: 'scc-der-impact-dashboard',
    component: SccDerImpactComponent,
    canActivate: [MsalGuard, PeaQuicksightGuard], data:{expctedRole: ['admin', 'super_user', 'igrid_viz_der']} ,
  },
  {
    path: 'disconnected-der-dashboard',
    component: DisconnectedDerComponent,
    canActivate: [MsalGuard, PeaQuicksightGuard], data:{expctedRole: ['admin', 'super_user', 'igrid_viz_der']} ,
  },
  {
    path: 'create-scenario',
    component: FormComponent,
    canActivate: [MsalGuard, PeaQuicksightGuard], data:{expctedRole: ['admin']} ,
  },
  {
    path: 'fuse-coordination',
    component: FuseCoordinationComponent,
    canActivate: [MsalGuard, PeaQuicksightGuard], data:{expctedRole: ['admin']} ,
  },
  {
    path: 'coming-soon',
    component: ComingSoonComponent,
    canActivate: [MsalGuard],
  },
  {
    path: 'pea-unauthorize',
    component: UnauthorizeComponent,
    canActivate: [MsalGuard],
  },
  {
    path: '',
    component: ScenarioSummaryComponent,
    canActivate: [MsalGuard, PeaQuicksightGuard], data:{expctedRole: ['admin']}
  },
  {
    path: 'login-failed',
    component: FailedComponent,
  },
  {
    // Needed for Error routing
    path: 'error',
    component: ScenarioSummaryComponent,
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      // Don't perform initial navigation in iframes or popups
      // Remove this line to use Angular Universal
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
