import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'pea-opensearch',
  templateUrl: './opensearch.component.html',
  styleUrls: ['./opensearch.component.scss']
})
export class OpensearchComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    console.log();
  }

}
