<mat-toolbar class="header__container">
    <ng-container [ngSwitch]="path">
        <!-- Refactor: Account for path params by filtering for highest level section of urlAfterRedirects -->
        <span *ngSwitchCase="'/'">
            <span class="header__container--view__name">Protective Equipment Analytics Dashboard</span>
        </span>
    </ng-container>
    <!-- <span *ngIf="user">{{user['name']}}</span> -->
    <span class="header__container--username__text">username, in header</span>
</mat-toolbar>
