import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { MessageService } from './message.service';
import { ProfileType } from '../models/profile-type';


// Endpoint for retrieving MS Graph profile for login information
const GRAPH_ENDPOINT = 'https://graph.microsoft.com/v1.0/me';



@Injectable({
  providedIn: 'root',
})
export class ProfileService {
  constructor(
    private messageService: MessageService,
    private httpClient: HttpClient
  ) { }

  /*
    this.http.get(GRAPH_ENDPOINT)
    .subscribe(profile => {
      this.profile = profile;
    });
  */

  getProfile(): Observable<ProfileType> {
    console.log('Calling profileService.getProfile');
    // return this.httpClient.get<String>(url, this.getHttpOptions()).pipe(
    return this.httpClient.get<ProfileType>(GRAPH_ENDPOINT).pipe(
      tap((_) => this.log(`getProfile called`)),
      catchError(this.handleError<ProfileType>(`getProfile`))
    );
  }

  private log(message: string): void {
    console.log(message);
    this.messageService.add(`profileService: ${message}`);
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation = 'operation', result?: T): any {
    return (error: any): Observable<T> => {
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
