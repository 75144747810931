import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { tap, catchError, throwError } from 'rxjs';
import { PeaUiConfigComponent } from '../components/pea-ui-config/pea-ui-config.component';

@Injectable({
  providedIn: 'root'
})
export class AuthorizerService {

  private baseUrl: string;

  constructor(private httpClient: HttpClient, private config: PeaUiConfigComponent) { 
      this.baseUrl = this.config.getConfig('aws_service_api'); 
    }

  private getHttpOptions() {
    // The MSAL interceptor will already add an auth token to the call, so we don't need to do that here, we will just 'add' to
    // the headers set.

    let httpOptions = {
    headers: new HttpHeaders({ 
      'Content-Type': 'application/json'})
    };
    
    return httpOptions;
  }

  getRole(){
    const idUrl: string = this.baseUrl + '/roles';
    return this.httpClient.get(idUrl, this.getHttpOptions())
    .pipe(catchError(this.processError));
  }

  processError(err: any) {
    let message = '';
    if(err.error instanceof ErrorEvent) {
     message = err.error.message;
    } else {
     message = `Error Code: ${err.status}\nMessage: ${err.message}`;
    }
    console.log(message);
    return throwError(message);
  }
}
