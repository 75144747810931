<div class="loadingActions">
    <pea-loader *ngIf="isLoading"></pea-loader>
</div>

<section class="pea-dashboard-data__dashboard__container">
    <div class="buttonDiv">
        <a href="https://dukeenergy.sharepoint.com/:w:/r/sites/O365-ProtectiveEquipmentAnalysisTeam/Shared%20Documents/General/DER_methodology.docx?d=w6ef5039ba1de404db7764a8cb428e82d&csf=1&web=1&e=pnkazY" class="docButton" target="_blank" rel="noopener noreferrer">Link to Documentation</a>
        <!-- <md-button class="docButton" ng-click="goDocumentation()">Link to Documentation</md-button> -->
    </div>
    <div class="pea-dashboard-data__dashboard__container--data__dashboard__container">
        <div id="dashboardContainer"></div>
    </div>
</section>
