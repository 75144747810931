<div>
    <mat-card>
        <form [formGroup]="fuseCoordinationForm">
            <div class="align-item">
                <mat-form-field >
                    <mat-label>Upsteam Fuse Type</mat-label>
                    <input matInput type="text" formControlName="upstream_fuse_type">
                </mat-form-field>
                <mat-form-field>
                    <mat-label>New Fuse Asset ID</mat-label>
                    <input matInput type="text" formControlName="new_fuse_asset_id">
                </mat-form-field>
            </div>
            <br>
            <div class="align-item">
                <mat-form-field >
                    <mat-label>Max Fault Current</mat-label>
                    <input matInput type="text" formControlName="max_fault_current">
                </mat-form-field>
                <mat-form-field>
                    <mat-label>Circuit ID</mat-label>
                    <input matInput type="text" formControlName="circuit_id">
                </mat-form-field>
                <mat-form-field>
                    <mat-label>Peak Load Flow Current</mat-label>
                    <input matInput type="text" formControlName="peak_load_flow_curent">
                </mat-form-field>
                <mat-form-field>
                    <mat-label>Downstream Fuse Type</mat-label>
                    <input matInput type="text" formControlName="downstream_fuse_type">
                </mat-form-field>
                <mat-form-field>
                    <mat-label>Output Date Field</mat-label>
                    <input matInput type="text" formControlName="output_date">
                </mat-form-field>
            </div>
        </form>
    </mat-card>
</div>

<app-chart-host chartType="line">
    <canvas
        baseChart
        [width]="600"
        [height]="600"
        [data]="lineChartData"
        [options]="lineChartOptions"
        [type]="lineChartType"
        (chartHover)="chartHovered($event)"
        (chartClick)="chartClicked($event)"
    ></canvas>
</app-chart-host>