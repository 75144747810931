<div class="loadingActions">
    <pea-loader *ngIf="isLoading"></pea-loader>
</div>
<ng-container *ngIf="scenarioData">
    <div>
        <h2 style="text-align: center; padding-top: 2%; font-weight: 570; color: #1260cc;">SCENARIO SUMMARY</h2>
    </div>
    <div style="display: flex; text-align: right; justify-content: right; margin-right: 7.4%;">
        <button mat-raised-button style="background-color: #689f38; color: white;" (click)="openForm()">+New</button>
    </div>
    <div style="padding: 1rem 5rem;">
        <pea-table
        [scenarioData] = "scenarioData"
        [scenarioTableColumns] = "scenarioTableColumns">
    </pea-table>
    </div>
</ng-container>