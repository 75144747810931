<div class="loadingActions">
    <pea-loader *ngIf="isLoading"></pea-loader>
</div>

<section class="pea-dashboard-data__dashboard__container">
    <div class="pea-dashboard-data__dashboard__container--data__dashboard__container">

        <div id="dashboardContainer"></div>

    </div>
</section>