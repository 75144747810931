import { BrowserModule } from '@angular/platform-browser';
import {
  APP_INITIALIZER,
  CUSTOM_ELEMENTS_SCHEMA,
  NgModule,
} from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BaseChartDirective, NgChartsModule } from 'ng2-charts';


import { MatButtonModule } from '@angular/material/button';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatMenuModule } from '@angular/material/menu';
import { MatTableModule } from '@angular/material/table';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCardModule } from '@angular/material/card';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatChipsModule } from '@angular/material/chips';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatPaginatorModule } from '@angular/material/paginator';
import { DeLoaderModule, DeHeaderModule } from '@de-electron/electron-angular-components';


// auth/config Libraries
import {
  MsalGuard,
  MsalInterceptor,
  MsalBroadcastService,
  MsalInterceptorConfiguration,
  MsalModule,
  MsalService,
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG,
  MsalGuardConfiguration,
  MsalRedirectComponent,
} from '@azure/msal-angular';
import {
  IPublicClientApplication,
  PublicClientApplication,
  InteractionType,
  BrowserCacheLocation,
  LogLevel,
  InteractionRequiredAuthError,
} from '@azure/msal-browser';

// config
import { environments } from '../config/env.config';
import { env } from '../config/env';

// modules
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from './modules/material.module';

// components
import { HomeComponent } from './components/home/home.component';
import { FailedComponent } from './components/failed/failed.component'; // login failure component
import { HeaderComponent } from './components/header/header.component';
import { SidenavComponent } from './components/sidenav/sidenav.component';
// import { FreeawsComponent } from './components/free-aws/freeaws.component';  // Test AWS API Gateway access (server side)
// Test local Flask server with CORS (only available locally, won't be available in AWS deployment)
// import { LocalTestComponent } from './components/local-test/local-test.component';

// CDK modules
import { LayoutModule } from '@angular/cdk/layout';
import { PeaGridComponent } from './components/pea-grid/pea-grid.component';
import { PeaUiConfigComponent } from './components/pea-ui-config/pea-ui-config.component';
import { UnauthorizeComponent } from './components/unauthorize/unauthorize.component';
import { KpiComponent } from './components/kpi/kpi.component';
import { ScenarioComponent } from './components/scenario-page/scenario/scenario.component';
import { OpensearchComponent } from './components/opensearch/opensearch.component';
import { FormComponent } from './resuable_components/form/form.component';
import { LoaderComponent } from './components/loader/loader.component';
import { ScenarioSummaryComponent } from './components/scenario-page/scenario-summary/scenario-summary.component';
import { PqviResultComponent } from './components/scenario-page/pqvi-result/pqvi-result.component';
import { BatchJobDashboardComponent } from './components/validation-page/batch-job-dashboard/batch-job-dashboard.component';
import { ToStringPipe } from './Pipe/to-string.pipe';
import { ComingSoonComponent } from './components/coming-soon/coming-soon.component';
import { SccResultComponent } from './components/scenario-page/scc-result/scc-result.component';
import { SccValidationComponent } from './components/validation-page/scc-validation/scc-validation.component';
import { PqviValidationComponent } from './components/validation-page/pqvi-validation/pqvi-validation.component';
import { PopUpComponent } from './components/pop-up/pop-up.component';
import { FuseCoordinationComponent } from './components/fuse-coordination/fuse-coordination.component';
import { SccDerImpactComponent } from './components/scc-der-impact-tab/scc-der-impact/scc-der-impact.component';
import { DisconnectedDerComponent } from './components/scc-der-impact-tab/disconnected-der/disconnected-der.component';

const isIE =
  window.navigator.userAgent.indexOf('MSIE ') > -1 ||
  window.navigator.userAgent.indexOf('Trident/') > -1; // Remove this line to use Angular Universal

export function loggerCallback(logLevel: LogLevel, message: string): void {
  // console.log(message);
}

export function MSALInstanceFactory(): IPublicClientApplication {
  let config: object = environments[env.env];
  return new PublicClientApplication({
    auth: {
      clientId: config['azure_client_id'], 
      authority: 'https://login.microsoftonline.com/' + config['azure_tenant'], // Duke tenant id
      redirectUri: config['azure_redirect_uri'],  // '/',
      postLogoutRedirectUri: '/'
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
      storeAuthStateInCookie: isIE, // set to true for IE 11. Remove this line to use Angular Universal
    },
    system: {
      loggerOptions: {
        loggerCallback,
        logLevel: LogLevel.Info,
        piiLoggingEnabled: false
      }
    }
  });
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  let config: object = environments[env.env];
  protectedResourceMap.set('https://graph.microsoft.com/v1.0/me', ['user.read']); // Required for authentication
  protectedResourceMap.set('http://localhost:5000', ['user.read']); // For local back end call
  protectedResourceMap.set(config['aws_service_api'], [config['aws_service_api_role']]);  // For AWS API  
  protectedResourceMap.set('https://protective-equipment-analysis-web-sbx.aws.duke-energy.com', ['api://a88f4312-a77b-4942-8af2-b08a5319fcc6/pea.read']); 

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: ['user.read'],
    },
    loginFailedRoute: '/login-failed',
  };
}

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    HeaderComponent,
    SidenavComponent,
    FailedComponent,
    PeaGridComponent,
    PeaUiConfigComponent,
    UnauthorizeComponent,
    KpiComponent,
    ScenarioComponent,
    OpensearchComponent,
    FormComponent,
    LoaderComponent,
    ScenarioSummaryComponent,
    PqviResultComponent,
    BatchJobDashboardComponent,
    ToStringPipe,
    ComingSoonComponent,
    SccResultComponent,
    SccDerImpactComponent,
    DisconnectedDerComponent,
    SccValidationComponent,
    PqviValidationComponent,
    PopUpComponent,
    FuseCoordinationComponent,
    //    FreeawsComponent,
    //    LocalTestComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    LayoutModule,
    MaterialModule,
    MatButtonModule,
    MatToolbarModule,
    MatMenuModule,
    MatTableModule,
    MatDialogModule,
    MatFormFieldModule,
    MatCardModule,
    MatSelectModule,
    MatSnackBarModule,
    MatTooltipModule,
    MatDatepickerModule,
    MatCheckboxModule,
    MatPaginatorModule,
    MatChipsModule,
    MatAutocompleteModule,
    DeLoaderModule,
    DeHeaderModule,
    MsalModule,
    NgChartsModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory,
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory,
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory,
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent, MsalRedirectComponent],
})
export class AppModule {}
