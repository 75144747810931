export enum LogLevel {
    NONE, // Self explanitory. outputs no logs
    LOG, // outputs all LOG message logs
    ERROR, // outputs all ERROR message logs and below (ERROR, LOG)
    WARN, // outputs all WARN message logs and below (WARN, ERROR, LOG)
    INFO, // outputs all INFO message logs and below (INFO, WARN, ERROR, LOG)
    DEBUG, // outputs all DEBUG message logs and below (DEBUG, WARN, ERROR, LOG)
    TRACE // outputs ALL message logs (TRACE, DEBUG, WARN, ERROR, LOG)
}

export const environments = {
    local: {
        aws_service_api: 'https://protective-equipment-analysis-api-sbx.duke-energy.app',
        aws_service_api_role: 'api://a88f4312-a77b-4942-8af2-b08a5319fcc6/pea.read',
        auth: '',
        azure_client_id: 'a88f4312-a77b-4942-8af2-b08a5319fcc6',
        azure_redirect_uri: 'http://localhost:4200/',
        azure_tenant: '2ede383a-7e1f-4357-a846-85886b2c0c4d',
        environment: 'SBX',
        help_email: 'PEA@duke-energy.com',
        log_level: LogLevel.WARN,
        de_auth_whitelist: ['@api@'],
        unauthorized_route: '/unauthorized',
        scenario_summary_dashboard_id: '7d689b1f-5915-408b-8ebb-5bf7d922494f',
        batch_job_dashboard_id: 'd0b12991-90c8-4ef3-afbe-2b137a29484e',
        scc_dashboard_id: '0574a442-4d08-4bda-b096-16e0c26a9b06',
        scc_der_impact_dashboard_id: '68f88cdc-76a6-446c-a013-a17e9b2771ee',
        disconnected_der_dashboard_id: '858e8b17-b755-43b2-ab8e-0c4c96ab0502',
        load_flow_dashboard_id: 'c5ce984a-137b-41f0-b766-a6c7c69e37ec',
        pqvi_validation_dashboard_id: '50382caa-571a-47ea-bd56-0f6c76af1df5',
        scc_validation_dashboard_id: 'c5ce984dda-137b-41f0-b766-a6c7c69e37ec',
        forecast_id: '78'
    },
    aws: {
        aws_service_api: '@api@',
        aws_service_api_role: '@api_role@',
        auth: '',
        azure_client_id: '@azure_client_id@',
        azure_redirect_uri: '@azure_redirect_uri@',
        azure_tenant: '@azure_tenant@',
        environment: '@environment@',
        help_email: '@help_email@',
        log_level: LogLevel.WARN,
        de_auth_whitelist: ['@api@'],
        unauthorized_route: '/unauthorized',
        scenario_summary_dashboard_id: '@scenario_summary_dashboard_id@',
        batch_job_dashboard_id: '@batch_job_dashboard_id@',
        scc_dashboard_id: '@scc_dashboard_id@',
        load_flow_dashboard_id: '@load_flow_dashboard_id@',
        pqvi_validation_dashboard_id: '@pqvi_validation_dashboard_id@',
        scc_validation_dashboard_id: '@scc_validation_dashboard_id@',
        scc_der_impact_dashboard_id: '@scc_der_impact_dashboard_id@',
        disconnected_der_dashboard_id: '@disconnected_der_dashboard_id@'
      }
    
};
