import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatAutocompleteSelectedEvent, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable, startWith, map } from 'rxjs';
import { PopUpComponent } from 'src/app/components/pop-up/pop-up.component';
import { PageHeaderService } from 'src/app/services/pageheader';
import { ScenarioService } from 'src/app/services/scenario.service';

@Component({
  selector: 'pea-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss']
})
export class FormComponent implements OnInit {
  pageHeader: string = 'CREATE SCENARIO'
  canSubmit: boolean = false;
  clickedElement: HTMLElement;
  isProcessorChecked: boolean = false;
  isLoading = true;
  scenarioForm: FormGroup;
  date = new Date('2022-08-24');
  model = 'true';
  // frozenModel = [true, false];
  skipPostProcess = [true, false];
  calculation_option = ['PQVI_PER_PH', 'PQVI_3_PH', 'SCC', 'DER', 'BASE_LOAD'];
  processorType = ['EPFS', 'PEA'];
  getRegion = [];
  getState = [];
  displayState = 'none';
  displayRegion = 'none';
  scenarioArray = [];
  responceData = [];
  scenarioInfo: string[];

  filteredSubstationID: Observable<string[]>;
  substationIDs: string[] = [];
  allSubstationIDs: string[] = [];
  substationInputValue: string;
  filteredSubstationIDOptions: string[];
  showAllSubstationIDs = false;
  substationAction = 0; 
  iCheckSubstation = 0;
  substationHelper = 0;

  filteredCircuitID: Observable<string[]>;
  circuitIDs: string[] = [];
  allCircuitIDs: string[] = [];
  circuitInputValue: string;
  filteredCircuitIDOptions: string[];
  showAllCircuitIDs = false;
  circuitAction = 0; 
  iCheckCircuit = 0;
  circuitHelper = 0;
  
  previousScenarios: {[key: string]: any} = {}; // Key: run_id, Value: Scenario params
  run_id: string;
  allRunIDs: string[] = ['Run IDs loading...']; 

  filteredState: Observable<string[]>;
  states: string[] = [];
  allStates: string[] = ['FLORIDA', 'INDIANA', 'KENTUCKY', 'OHIO', 'NORTH CAROLINA', 'SOUTH CAROLINA'];

  filteredRegion: Observable<string[]>;
  regions: string[] = [];
  allRegions: string[] = ['DEC', 'DEF', 'DEM', 'DEP'];

  filteredForecastDriver: Observable<string[]>;
  forecastDrivers: string[] = ['BASE', 'EV', 'UEE'];
  allForecastDrivers: string[] = ['BASE', 'SPOT', 'EV', 'UEE'];

  @ViewChild('substationID') substationID: ElementRef<HTMLInputElement>;
  @ViewChild('circuitID') circuitID: ElementRef<HTMLInputElement>;
  @ViewChild('istate') istate: ElementRef<HTMLInputElement>;
  @ViewChild('iregion') iregion: ElementRef<HTMLInputElement>;
  @ViewChild('iforecastDriver') iforecastDriver: ElementRef<HTMLInputElement>;
  @ViewChild(MatAutocompleteTrigger) auto!: MatAutocompleteTrigger;

  constructor( private fb: FormBuilder, private snackBar: MatSnackBar, private scenarioScervice: ScenarioService, private pageHeaderService: PageHeaderService, public dialog: MatDialog) {

    this.pageHeaderService.emitData(this.pageHeader);
    
    //Query pea_processing_metadata in pea_e1_results_cur to get previous scenario run IDs / scenarios
    this.scenarioScervice.getScenario().subscribe(res => {
      console.log(res);
      this.setPreviousScenarios(res);
    })
    
    //get Scenario Info
    this.scenarioScervice.getScenarioInfo().subscribe(res => {
      this.setScenarioInfo(res);
      console.log(res);
      this.isLoading = false;
    })

    const currentYear = this.getCurrentYear();
    
    this.scenarioForm = this.fb.group({
      scenario_name: new FormControl('', [Validators.required]),
      run_id: new FormControl(''),
      regions: new FormControl(''),
      states: new FormControl(''),
      substation_ids: new FormControl(''),
      circuit_ids: new FormControl(''),
      forecast_driver: new FormControl('', [Validators.required]),
      // frozen_model: new FormControl(true, [Validators.required]),
      calculation_option: new FormControl('DER', [Validators.required]),
      processor_type: new FormControl('PEA', [Validators.required]),
      skip_postprocess: new FormControl(false, [Validators.required]),
      image_tag: new FormControl('')
  })


  this.filteredSubstationID = this.scenarioForm.controls['substation_ids'].valueChanges.pipe(
    startWith(null),
    map((data: string | null) => (data ? this._filterSubstationID(data) : this.allSubstationIDs.slice())),
  );

  this.filteredCircuitID = this.scenarioForm.controls['circuit_ids'].valueChanges.pipe(
    startWith(null),
    map((data: string | null) => (data ? this._filterCircuitID(data) : this.allCircuitIDs.slice())),
  );

  this.filteredState = this.scenarioForm.controls['states'].valueChanges.pipe(
    startWith(null),
    map((data: string | null) => (data ? this._filterState(data) : this.allStates.slice())),
  );

  this.filteredRegion = this.scenarioForm.controls['regions'].valueChanges.pipe(
    startWith(null),
    map((data: string | null) => (data ? this._filterRegion(data) : this.allRegions.slice())),
  );

  this.filteredForecastDriver = this.scenarioForm.controls['forecast_driver'].valueChanges.pipe(
    startWith(null),
    map((data: string | null) => (data ? this._filterForecastDriver(data) : this.allForecastDrivers.slice())),
  );
}

  ngOnInit(): void {
    if(this.forecastDrivers.length > 0){
      this.scenarioForm.controls['forecast_driver'].setValue(this.forecastDrivers);
    }
    this.scenarioForm.valueChanges.subscribe(data => {
      if (this.regions.length > 0 || this.states.length > 0 || this.substationIDs.length > 0 || this.circuitIDs.length > 0) {
        this.canSubmit = true;
      }
    })
    this.scenarioForm.get('run_id').valueChanges.subscribe(newValue => {
      // Update params to match previous scenario
      console.log("Loading previous scenario params for run ID: " + newValue);
      const loadScenario = this.previousScenarios[newValue];

      this.scenarioForm.get('scenario_name').setValue(loadScenario['scenario_name'])

      this.regions = loadScenario['regions'];
      this.states = loadScenario['states'];

      this.circuitIDs = loadScenario['circuit_ids'];
      this.substationIDs = loadScenario['substation_ids'];

      this.forecastDrivers = loadScenario['forecast_driver'].split("-");
      
      this.scenarioForm.get('calculation_option').setValue(loadScenario['calculation_option']);
      this.scenarioForm.get('processor_type').setValue(loadScenario['processor_type']);

      // var frozen_model_prev = loadScenario['frozen_model'].toLowerCase() == 'true' ? true : false;
      // this.scenarioForm.get('frozen_model').setValue(frozen_model_prev);

      var skip_postprocess_prev = loadScenario['skip_postprocess'].toLowerCase() == 'true' ? true : false;
      this.scenarioForm.get('skip_postprocess').setValue(skip_postprocess_prev);
    })
    if(this.scenarioInfo){
      this.setScenarioInfo(this.scenarioInfo);
    }
  }

  getCurrentYear(){
    const currentDate = new Date();
    return currentDate.getFullYear();
  }

  setPreviousScenarios(data: any) {
    const runId = [];
    const prevScenarios: {[key: string]: any} = {}; // Key: run_id, Value: Scenario params
    console.log(data);
    for(let item in data){
      runId.push(data[item]['run_id']);
      prevScenarios[data[item]['run_id']] = data[item];
      prevScenarios[data[item]['run_id']]['regions'] = JSON.parse(data[item]['regions']);
      prevScenarios[data[item]['run_id']]['states'] = JSON.parse(data[item]['states']);
      prevScenarios[data[item]['run_id']]['circuit_ids'] = JSON.parse(data[item]['circuit_ids']);
      prevScenarios[data[item]['run_id']]['substation_ids'] = JSON.parse(data[item]['substation_ids']);
    }
    this.allRunIDs = [...new Set(runId)];
    this.allRunIDs.sort();
    this.previousScenarios = prevScenarios;
    console.log("scenarios");
    console.log(this.previousScenarios);
  }

  setScenarioInfo(data: any){
    const substationId = [];
    const circuitId = [];
    const runId = [];
    for(let item in data){
      substationId.push(data[item].SubstationId);
      circuitId.push(data[item].CircuitId);
    }
    this.allSubstationIDs = [...new Set(substationId)];
    this.allSubstationIDs.sort();
    this.filteredSubstationIDOptions = this.allSubstationIDs
    this.allCircuitIDs = [...new Set(circuitId)];
    this.allCircuitIDs.sort();
  }

  // Multiselect Substation ID Actions
  removeSubstationID(data: string): void {
    const index = this.substationIDs.indexOf(data);

    if (index >= 0) {
      this.substationIDs.splice(index, 1);
      if (index <= 2){
        this.showAllSubstationIDs = false;
        this.substationAction = 0;
        this.iCheckSubstation = 0;
        this.substationHelper = 0;
      }
    }
    this.checkCanSubmit();
  }

  selectedSubstationID(event: MatAutocompleteSelectedEvent): void {
    if(this.substationIDs.length === 0){
      this.substationIDs.push(event.option.viewValue);
      this.substationID.nativeElement.value = '';
      this.scenarioForm.controls['substation_ids'].setValue(null);
      this.filteredSubstationIDOptions = this.allSubstationIDs;
    }
    else if(this.substationIDs.includes(event.option.viewValue)){
      this.filteredSubstationIDOptions = this.allSubstationIDs;
      this.substationID.nativeElement.value = '';
      this.snackBar.open('SELECTED  ')
      setTimeout(() => {
        this.snackBar.dismiss();
      }, 2000); 
    }
    else{
      this.substationIDs.push(event.option.viewValue);
      this.substationID.nativeElement.value = '';
      this.scenarioForm.controls['substation_ids'].setValue(null);
      this.filteredSubstationIDOptions = this.allSubstationIDs;
    }
  }

  onSubstationInputChange(){
    this.filteredSubstationIDOptions = this.allSubstationIDs.filter(id => id.toLowerCase().includes(this.substationInputValue.toLowerCase()));
  }

  viewSelectedSubstationID(){
    this.substationIDs.sort();
    const dialogRef = this.dialog.open(PopUpComponent, {
      data: {
        filterData: this.substationIDs,
      },
    });
  }

  private _filterSubstationID(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.allSubstationIDs.filter(data => data.toLowerCase().includes(filterValue));
  }

  // Multiselect Circuit ID Actions
  removeCircuitID(data: string): void {
    const index = this.circuitIDs.indexOf(data);

    if (index >= 0) {
      this.circuitIDs.splice(index, 1);
      if (index <= 2){
        this.showAllCircuitIDs = false;
        this.circuitAction = 0;
        this.iCheckCircuit = 0;
        this.circuitHelper = 0;
      }
    }
    this.checkCanSubmit();
  }

  selectedCircuitID(event: MatAutocompleteSelectedEvent): void {
    if(this.circuitIDs.length === 0){
      this.circuitIDs.push(event.option.viewValue);
      this.circuitID.nativeElement.value = '';
      this.scenarioForm.controls['circuit_ids'].setValue(null);
      this.filteredCircuitIDOptions = this.allCircuitIDs;
    }
    else if(this.circuitIDs.includes(event.option.viewValue)){
      this.filteredCircuitIDOptions = this.allCircuitIDs;
      this.circuitID.nativeElement.value = '';
      this.snackBar.open('SELECTED  ')
      setTimeout(() => {
        this.snackBar.dismiss();
      }, 2000); 
    }
    else{
      this.circuitIDs.push(event.option.viewValue);
      this.circuitID.nativeElement.value = '';
      this.scenarioForm.controls['circuit_ids'].setValue(null);
      this.filteredCircuitIDOptions = this.allCircuitIDs;
    }
  }

  onCircuitInputChange(){
    this.filteredCircuitIDOptions = this.allCircuitIDs.filter(id => id.toLowerCase().includes(this.circuitInputValue.toLowerCase()));
  }

  viewSelectedCircuitID(){
    this.circuitIDs.sort();
    const dialogRef = this.dialog.open(PopUpComponent, {
      data: {
        filterData: this.circuitIDs,
      },
    });
  }

  private _filterCircuitID(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.allCircuitIDs.filter(data => data.toLowerCase().includes(filterValue));
  }

  // Multiselect State Actions
  removeState(data: string): void {
    const index = this.states.indexOf(data);

    if (index >= 0) {
      this.states.splice(index, 1);
    }
    this.checkCanSubmit();
  }

  selectedState(event: MatAutocompleteSelectedEvent): void {
    if(this.states.length === 0){
      this.states.push(event.option.viewValue);
      this.istate.nativeElement.value = '';
      this.scenarioForm.controls['states'].setValue(null);
    }
    else if(this.states.includes(event.option.viewValue)){
      this.snackBar.open('SELECTED  ')
      setTimeout(() => {
        this.snackBar.dismiss();
      }, 2000); 
    }
    else{
      this.states.push(event.option.viewValue);
      this.istate.nativeElement.value = '';
      this.scenarioForm.controls['states'].setValue(null);
    }
  }

  private _filterState(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.allStates.filter(data => data.toLowerCase().includes(filterValue));
  }

  // Multiselect Region Actions
  removeRegion(data: string): void {
    const index = this.regions.indexOf(data);

    if (index >= 0) {
      this.regions.splice(index, 1);
    }
    this.checkCanSubmit();
  }

  selectedRegion(event: MatAutocompleteSelectedEvent): void {
    if(this.regions.length === 0){
      this.regions.push(event.option.viewValue);
      this.iregion.nativeElement.value = '';
      this.scenarioForm.controls['regions'].setValue(null);
    }
    else if(this.regions.includes(event.option.viewValue)){
      this.snackBar.open('SELECTED  ')
      setTimeout(() => {
        this.snackBar.dismiss();
      }, 2000); 
    }
    else{
      this.regions.push(event.option.viewValue);
      this.iregion.nativeElement.value = '';
      this.scenarioForm.controls['regions'].setValue(null);
    }
  }

  private _filterRegion(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.allRegions.filter(data => data.toLowerCase().includes(filterValue));
  }

  // Multiselect Forecast Driver Actions
  removeForecastDriver(data: string): void {
    const index = this.forecastDrivers.indexOf(data);

    if (index >= 0) {
      this.forecastDrivers.splice(index, 1);
    }
    if(this.forecastDrivers.length === 0){
      this.scenarioForm.controls['forecast_driver'].setValue(null);
    }
  }

  selectedForecastDriver(event: MatAutocompleteSelectedEvent): void {
    if(this.forecastDrivers.length === 0){
      this.forecastDrivers.push(event.option.viewValue);
      this.iforecastDriver.nativeElement.value = '';
      this.scenarioForm.controls['forecast_driver'].setValue(event.option.viewValue);
    }
    else if(this.forecastDrivers.includes(event.option.viewValue)){
      this.snackBar.open('SELECTED  ')
      setTimeout(() => {
        this.snackBar.dismiss();
      }, 2000); 
    }
    else{
      // this.forecastDrivers = [];
      this.forecastDrivers.push(event.option.viewValue);
      this.iforecastDriver.nativeElement.value = '';
      this.scenarioForm.controls['forecast_driver'].setValue(event.option.viewValue);
    }
  }

  private _filterForecastDriver(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.allForecastDrivers.filter(data => data.toLowerCase().includes(filterValue));
  }
  

  // checkModel(e: any){
  //   if(e.value == false){
  //     this.isLoading = true;
  //     //Query morecast_cur_driver_combination table to get Scenario Info
  //     this.scenarioScervice.getScenarioInfo().subscribe(res => {
  //       this.setScenarioInfo(res);
  //       this.isLoading = false
  //       this.substationIDs = [];
  //       this.circuitIDs = [];
  //     })
  //   }
  //   else if(e.value == true){
  //     this.isLoading = true;
  //     //Get Scenario Info from frozen_model_cur_driver_combination table
  //     this.setScenarioInfo(this.scenarioInfo)
  //     this.isLoading = false;
  //     this.substationIDs = [];
  //     this.circuitIDs = [];
  //   }    
  // }

  checkProcessor(e: any){
    if(e.value == 'EPFS'){
      this.isProcessorChecked = true;
    }
    else if(e.value == 'PEA'){
      this.isProcessorChecked = false;
      this.scenarioForm.controls['image_tag'].setValue(null)
    }
    
  }

  reset(){
    this.scenarioForm.reset();
    this.forecastDrivers=[];
    this.getRegion = [];
    this.getState = [];
    this.substationIDs = [];
    this.circuitIDs = [];
    this.states = [];
    this.regions = [];
  }

  submit(){
    let forecastDriver = this.forecastDrivers.join('-')
    let scenarioData = {
      circuit_ids: this.circuitIDs,
      forecast_driver: forecastDriver,
      // frozen_model: this.scenarioForm.controls['frozen_model'].value,
      calculation_option: this.scenarioForm.controls['calculation_option'].value,
      processor_type: this.scenarioForm.controls['processor_type'].value,
      skip_postprocess: this.scenarioForm.controls['skip_postprocess'].value,
      regions: this.regions,
      states: this.states,
      scenario_name: this.scenarioForm.controls['scenario_name'].value,
      substation_ids: this.substationIDs
    }
    if(this.scenarioForm.controls['image_tag'].value != '' && this.scenarioForm.controls['image_tag'].value != null){
      Object.assign(scenarioData, {processor_image_tag: this.scenarioForm.controls['image_tag'].value})
    }
    console.log(scenarioData);
    
    this.scenarioScervice.triggerScenario(scenarioData).subscribe(res => {
    })

    this.snackBar.open('SUBMITTED', 'DONE')
    setTimeout(() => {
      this.snackBar.dismiss();
    }, 2000);
    
  }

  checkCanSubmit(){
    if (this.regions.length == 0 && this.states.length == 0 && this.substationIDs.length == 0 && this.circuitIDs.length == 0) {
      this.canSubmit = false;
    }
  }
}
