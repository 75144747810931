import { HttpClient } from '@angular/common/http';
import { createEmbeddingContext } from 'amazon-quicksight-embedding-sdk';
import { Component, OnInit } from '@angular/core';
import { DashboardService } from 'src/app/services/dashboard.service';
import { ProfileService } from 'src/app/services/profile.service';
import { ProfileType } from '../../profile/profile.component';
import { PeaUiConfigComponent } from '../../pea-ui-config/pea-ui-config.component';
import { PageHeaderService } from 'src/app/services/pageheader';

@Component({
  selector: 'pea-scenario-summary',
  templateUrl: './scenario-summary.component.html',
  styleUrls: ['./scenario-summary.component.scss']
})
      
export class ScenarioSummaryComponent implements OnInit {
  pageHeader: string = 'SCENARIO SUMMARY'
  isLoading = true;
  dashboard: any;
  dashboardUrl: string = "";
  emailValue: string|undefined;
  scenarioInfo: Array<string>;
  scenario_summary_dashboard_id: string;

  constructor(private http: HttpClient,
    private dashboardService: DashboardService, 
    private profileService: ProfileService,
    private config: PeaUiConfigComponent,
    private pageHeaderService: PageHeaderService
    ) { 
      this.pageHeaderService.emitData(this.pageHeader);
      this.scenario_summary_dashboard_id = this.config.getConfig('scenario_summary_dashboard_id');
    }

    ngOnInit(): void {
      this.getDashboardURL(this.scenario_summary_dashboard_id);
    }
  
    public async getDashboardURL(id: string) {
      const profileType: ProfileType = await this.profileService.getProfile().toPromise();
      this.emailValue = profileType?.userPrincipalName;
      if (this.emailValue) {
        const email: string = this.emailValue;
        this.dashboardUrl = await this.dashboardService.getScenarioDashboardUrl(email,id).toPromise()
        this.Dashboard(this.dashboardUrl);
        this.isLoading = false;
      }
    }
    
    public async Dashboard(embeddedURL: any) {
      var containerDiv = document.getElementById("dashboardContainer");
      console.log("Creating quick scenario dashboard with url ="+ JSON.stringify(embeddedURL));

      var frameOptions = {
        url: embeddedURL,
        container: containerDiv,
        scrolling: "yes",
        iframeResizeOnSheetChange: true,
        overflow: "auto",
        width: "100%",
        height : "850px"
      };
  
      var contentOptions = {
        locale: "en-US"
      };

      const embeddingContext = await createEmbeddingContext();
      
      const {
        embedDashboard
      } = embeddingContext; 

      this.dashboard = embedDashboard(frameOptions,contentOptions);
    }
    
    onError(arg0: string, onError: any) {
      console.log("onError triggered with " + JSON.stringify(arg0) + " and any =" + JSON.stringify(onError));
    }
    
    onDashboardLoad(arg0: string, onDashboardLoad: any) {
      console.log("onDashboardLoad triggered with " + JSON.stringify(arg0) + " and any =" + JSON.stringify(onDashboardLoad));
    }

}
