<div class="loadingActions">
  <pea-loader *ngIf="!loginDisplay"></pea-loader>
</div>

<div *ngIf="loginDisplay">
  <div class="container">
    <div class="tabs">
      <div class="box active" [routerLink]="['/scenario-dashboard']">Scenario</div>
      <div class="box" [routerLink]="['/scenario-dashboard-v2']">Scenario V2</div>
      <div class="box" [routerLink]="['/kpi-dashboard']">KPI</div>
      <div class="box" [routerLink]="['/opensearch-dashboard']">Opensearch</div>
      <div class="box" [routerLink]="['/validation-dashboard']">Validation</div>
    </div>
  </div>
</div>
