import { Injectable } from '@angular/core';

import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { catchError, tap, timeout } from 'rxjs/operators';

import { MessageService } from './message.service';
import { PeaUiConfigComponent } from '../components/pea-ui-config/pea-ui-config.component';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  private baseUrl: string;
  private jobSubmissionDisplaySecs: string;

  constructor(private messageService: MessageService, 
    private httpClient: HttpClient,
    private config: PeaUiConfigComponent,
) { 
    this.baseUrl = this.config.getConfig('aws_service_api') + '/dashboard';  
    this.jobSubmissionDisplaySecs = this.config.getConfig('job_submission_display_time_secs');
  }

  getJobSubmissionDisplaySecs(): number {
    const val = Number(this.jobSubmissionDisplaySecs);
    return val;
  }
  
  private getHttpOptions() {
    // The MSAL interceptor will already add an auth token to the call, so we don't need to do that here, we will just 'add' to
    // the headers set.

    let httpOptions = {
    headers: new HttpHeaders({ 
      'Content-Type': 'application/json'})
    };
    
    return httpOptions;
  }

  getDashboardUrl(email: string): Observable<string> {
    this.log("************ Service being called *****" + email);
    const idUrl: string = this.baseUrl + '/dashboard-url/' + email;
    this.log("************ Service being called ***** w idUrl = " + idUrl);
    const dashboardUrl = this.httpClient.get<string>(idUrl, this.getHttpOptions()).pipe(
      tap(_ => this.log(`^^^^^^^^^^^^^^^^^^^^  fetched dashboard URL ${dashboardUrl}`)),
      catchError(this.handleError<string>('getDashboardUrl'))
    );

    return dashboardUrl;
  }

  getDerImpactDashboardUrl(email: string, id: string): Observable<string> {
    const idUrl: string = this.baseUrl + '/scc-der-impact';
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    let params = new HttpParams();
    params = params.append("dashboard_id", id);
    params = params.append("email", email);
    const dashboardUrl = this.httpClient.get<string>(idUrl, {headers, params}).pipe(
      tap(_ => this.log(`^^^^^^^^^^^^^^^^^^^^  fetched dashboard URL ${dashboardUrl}`)),
      catchError(this.handleError<string>('getDashboardUrl'))
    );

    return dashboardUrl;
  }

  getDisconnectedDerDashboardUrl(email: string, id: string): Observable<string> {
    const idUrl: string = this.baseUrl + '/disconnected-der';
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    let params = new HttpParams();
    params = params.append("dashboard_id", id);
    params = params.append("email", email);
    const dashboardUrl = this.httpClient.get<string>(idUrl, {headers, params}).pipe(
      tap(_ => this.log(`^^^^^^^^^^^^^^^^^^^^  fetched dashboard URL ${dashboardUrl}`)),
      catchError(this.handleError<string>('getDashboardUrl'))
    );

    return dashboardUrl;
  }

  getScenarioDashboardUrl(email: string, id: string): Observable<string> {
    const idUrl: string = this.baseUrl + '/scenarios';
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    let params = new HttpParams();
    params = params.append("dashboard_id", id);
    params = params.append("email", email);
    const dashboardUrl = this.httpClient.get<string>(idUrl, {headers, params}).pipe(
      tap(_ => this.log(`^^^^^^^^^^^^^^^^^^^^  fetched dashboard URL ${dashboardUrl}`)),
      catchError(this.handleError<string>('getDashboardUrl'))
    );

    return dashboardUrl;
  }

  getScenarioResultDashboardUrl(email: string, id: string): Observable<string> {
    const idUrl: string = this.baseUrl + '/scenarios/result';
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    let params = new HttpParams();
    params = params.append("dashboard_id", id);
    params = params.append("email", email);
    const dashboardUrl = this.httpClient.get<string>(idUrl, {headers, params}).pipe(
      tap(_ => this.log(`^^^^^^^^^^^^^^^^^^^^  fetched dashboard URL ${dashboardUrl}`)),
      catchError(this.handleError<string>('getDashboardUrl'))
    );

    return dashboardUrl;
  }

  getBatchJobDashboardUrl(email: string, id: string): Observable<string> {
    const idUrl: string = this.baseUrl + '/batch-job';
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    let params = new HttpParams();
    params = params.append("dashboard_id", id);
    params = params.append("email", email);
    const dashboardUrl = this.httpClient.get<string>(idUrl, {headers, params}).pipe(
      tap(_ => this.log(`^^^^^^^^^^^^^^^^^^^^  fetched dashboard URL ${dashboardUrl}`)),
      catchError(this.handleError<string>('getDashboardUrl'))
    );

    return dashboardUrl;
  }
  
  private log(message: string) {
    console.log(message);
    this.messageService.add(`JobStatusService: ${message}`)
  }

  
  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }  


}

