import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FormComponent } from 'src/app/resuable_components/form/form.component';
import { ScenarioService } from 'src/app/services/scenario.service';

export interface ScenarioData {
  scenario_id : string;
  scenario_name : string;
  batch_status : string;
  regions : string;
  states : string;
  substation_ids: string;
  circuit_id : string;
  forecast_driver : string;
  forecast_id : string;
  gdq_load_dt : string;
  run_id : string;
  // frozen_model: string;
}

@Component({
  selector: 'pea-scenario',
  templateUrl: './scenario.component.html',
  styleUrls: ['./scenario.component.scss']
})
export class ScenarioComponent implements OnInit {
  isLoading = true;
  scenarioData: Array<string>;
  scenarioInfo: Array<string>;
  scenarioTableColumns: string[] = ['scenario_id', 'scenario_name', 'batch_status', 'run_id', 'edit', 'result'];

  constructor( public dialog: MatDialog, private scenarioScervice: ScenarioService) {
    //get Scenario Info
    this.scenarioScervice.getScenarioInfo().subscribe(res => {
      this.scenarioInfo = res
    })
   }

  ngOnInit(): void {
    this.scenarioScervice.getScenario().subscribe(res => {
      this.scenarioData = res
      this.isLoading = false;
    })
  }

  openForm(){
    const dialogRef = this.dialog.open(FormComponent, {
      width: '1000px',
      height: 'auto',
      data: {scenarioInfo: this.scenarioInfo}
    })
  }

}
