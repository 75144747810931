<div class="left-nav">
    <h3>Scenario</h3>
    <ul>
        <li (click)="changeFontColor('/', 'SCENARIO SUMMARY')" [ngStyle]="{'color': pageHeader === 'SCENARIO SUMMARY' ? 'black' : 'gray', 'font-weight': pageHeader === 'SCENARIO SUMMARY' ? 'bold' : ''}">Scenario Summary</li>
        <li (click)="changeFontColor('create-scenario','CREATE SCENARIO')" [ngStyle]="{'color': pageHeader === 'CREATE SCENARIO' ? 'black' : 'gray', 'font-weight': pageHeader === 'CREATE SCENARIO' ? 'bold' : ''}">Create Scenario</li>
        <li (click)="changeFontColor('scc-result-dashboard', 'SCC RESULTS')" [ngStyle]="{'color': pageHeader === 'SCC RESULTS' ? 'black' : 'gray', 'font-weight': pageHeader === 'SCC RESULTS' ? 'bold' : ''}">SCC Results</li>
        <li (click)="changeFontColor('pqvi-result-dashboard', 'PQVI RESULTS')" [ngStyle]="{'color': pageHeader === 'PQVI RESULTS' ? 'black' : 'gray', 'font-weight': pageHeader === 'PQVI RESULTS' ? 'bold' : ''}">PQVI Results</li>
    </ul>
    <h3>Coordination</h3>
    <ul>
        <li (click)="changeFontColor('fuse-coordination', 'FUSE COORDINATION')" [ngStyle]="{'color': pageHeader === 'FUSE COORDINATION' ? 'black' : 'gray', 'font-weight': pageHeader === 'FUSE COORDINATION' ? 'bold' : ''}">Fuse Coordination</li>
    </ul>
    <h3>Validation</h3>
    <ul>
        <li (click)="changeFontColor('scc-validation-dashboard', 'SCC VALIDATION')" [ngStyle]="{'color': pageHeader === 'SCC VALIDATION' ? 'black' : 'gray', 'font-weight': pageHeader === 'SCC VALIDATION' ? 'bold' : ''}">SCC Validation</li>
        <li (click)="changeFontColor('pqvi-validation-dashboard', 'PQVI VALIDATION')" [ngStyle]="{'color': pageHeader === 'PQVI VALIDATION' ? 'black' : 'gray', 'font-weight': pageHeader === 'PQVI VALIDATION' ? 'bold' : ''}">PQVI Validation</li>
    </ul>
    <h3>SCC DER Impact</h3>
    <ul>
        <li (click)="changeFontColor('scc-der-impact-dashboard', 'SCC DER IMPACT')" [ngStyle]="{'color': pageHeader === 'SCC DER IMPACT' ? 'black' : 'gray', 'font-weight': pageHeader === 'SCC DER IMPACT' ? 'bold' : ''}">SCC DER Impact</li>
    </ul>
    <ul>
        <li (click)="changeFontColor('disconnected-der-dashboard', 'Disconnected DER')" [ngStyle]="{'color': pageHeader === 'Disconnected DER' ? 'black' : 'gray', 'font-weight': pageHeader === 'Disconnected DER' ? 'bold' : ''}">Disconnected DER</li>
    </ul>
</div>