import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, map } from 'rxjs';
import { AuthorizerService } from '../services/authorizer.service';

@Injectable({
  providedIn: 'root'
})
export class PeaQuicksightGuard {

  emailValue: string|undefined;
  dashboardUrl: string = "";
  role: string[];
  isRole = false;

  constructor(private router: Router, private authorizerService: AuthorizerService ){}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.checkQuickRole(route);
  }

  checkQuickRole(route:ActivatedRouteSnapshot ){
    let expctedRole = route.data.expctedRole;

    return this.authorizerService.getRole().pipe(map(res => {
      this.role = Object.values(res);
      if(!this.role){
        this.router.navigate(['pea-unauthorize']);
      }
      this.isRole = this.checkRole(this.role, expctedRole);
    
    if(this.isRole == false){
      this.router.navigate(['pea-unauthorize']);
    }
    else if(this.isRole == true){
      return true;
    }
    }));
  } 

  checkRole(role: string[], expectedRole: string[]): boolean{
    // check if the role(s) assigned has access to the page
    const result = role.some(element => expectedRole.includes(element));
    // console.log("role:" + role)
    // console.log("expectedRole:" + expectedRole)
    return result;
  }
}
