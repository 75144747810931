import { Injectable, EventEmitter } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PageHeaderService {

  updatePageHeader = new EventEmitter<string>();
  updateSidenavCheck = new EventEmitter<boolean>();

  emitData(data: string){
    this.updatePageHeader.emit(data);
  }

  emitSidenavCheck(data: boolean){
    this.updateSidenavCheck.emit(data);
  }
}