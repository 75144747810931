import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ProfileService } from '../../services/profile.service';
import { MsalService } from '@azure/msal-angular';
import { PageHeaderService } from 'src/app/services/pageheader';

// Endpoint for retrieving MS Graph profile for login information
const GRAPH_ENDPOINT = 'https://graph.microsoft.com/v1.0/me';

/*
type ProfileType = {
  givenName?: string,
  surname?: string,
  userPrincipalName?: string,
  id?: string
};
*/

export class ProfileType {
  givenName?: string;
  surname?: string;
  userPrincipalName?: string;
  id?: string;
}

@Component({
  selector: 'pea-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit {
  pageHeader: string = 'PROFILE'
  profile!: ProfileType;

  constructor(
    private profileService: ProfileService,
    private pageHeaderService: PageHeaderService
  ) {
    this.pageHeaderService.emitData(this.pageHeader);
  }

  ngOnInit(): void {
    this.getProfile();
  }

  getProfile(): void {
    console.log('localTest ngOnInit about to call profileService.getProfile');
    this.profileService.getProfile().subscribe((res: ProfileType) => {
      console.log('return res = ' + res);
      this.profile = res;
    });
  }
}
